import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/home/home.vue')
    },
    {
      path: '/incubation',
      name: 'incubation',
      component: () => import('../views/incubation/incubation.vue')
    },
    {
      path: '/production',
      name: 'production',
      component: () => import('../views/production/production.vue')
    },
    {
      path: '/mass',
      name: 'mass',
      component: () => import('../views/mass/mass.vue')
    }
]

  //添加以下代码--解决路由重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 创建路由实例
const router = new VueRouter({
    mode: 'history', // 根据项目需求选择路由模式，默认是 hash 模式，也可选 history 模式
    base: process.env.BASE_URL, // 如果有特定的基础路径需要设置
    routes // （缩写）相当于 routes: routes
})

router.beforeEach((to, from, next) => {
  // document.title = to.meta.title;
  document.title = '九赋官网';
  // let hasToken = localStorage.getItem('hasToken');
  // if(hasToken){
    next();
  // }else{
  //   if(to.path == '/login'){
  //     return next();
  //   }else{
  //     next({path:'/'});
  //   }
  // }
})

export default router